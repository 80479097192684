import React, { useEffect,useRef } from 'react';
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
import Markdown from "markdown-to-jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import termsStyle from "ps-assets/jss/termsOfUsageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import PropTypes from "prop-types";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.jsx";
import { Helmet } from "react-helmet";
import {  browserWindow,browserDocument } from "util/localStorageHelper";
import { sevaApp } from 'util/LayoutUtil.jsx';
const stringSearchRegExp = /<website \/>/g
const WEBSITE = 'Website';
const APP = 'Website/App';
const Disclaimer = ({ classes, data }) => {
  var Images = data.allStrapiImages.edges;
  var bwImage = Images[0];
  var clrImage = Images[1];
  var DisclaimerpageContent,Privacydata,Alldata=[];
  let disclaimer=useRef();
  let privacy_policy=useRef();
  let terms=useRef();
  data.allStrapiEntities.edges.map((item)=>{
    if(item.node.category=='disclaimer'){
      DisclaimerpageContent=item.node.description
    }
    else if(item.node.category=='privacy_policy'){
      Privacydata=item.node
    }else{
      Alldata.push(item)
    }
  })
  var Title,
    RightCards = [],
    LeftCards = [];
  for (let i in Alldata) {
    let document = Alldata[parseInt(i)];
    if(document.node && document.node.summary){
    let descArr = document.node.summary.split(`\n`);
    let desc = descArr.map((line, index) => (
      <span key={`line-` + index}>
        {(document.node.strapiId != 253 && document.node.strapiId != 324 && document.node.strapiId != 309) ? 
        (<>
          {line && (<><p>
            {/* {line} */}
            {sevaApp() ? (line).replace(stringSearchRegExp, APP) : (line).replace(stringSearchRegExp, WEBSITE)}
            </p>
            </>)}
            </>) : 
            (<>
              {line && (<><p style={{fontSize:'17px',paddingBottom:'1%'}}>
                {/* {line} */}
                {sevaApp() ? (line).replace(stringSearchRegExp, APP) : (line).replace(stringSearchRegExp, WEBSITE)}
                </p>
                </>)}
                </>)
      }        
      </span>
    ));
    if (document.node.strapiId != 253 && document.node.strapiId != 324 && document.node.strapiId != 309 ) {
      if (document.node.sequence < 7) {
        LeftCards.push(
          <Card
            blog
            className={classes.customCard}
            style={{ marginTop: `10px` }}
            key={`left-terms-${i}`}
          >
            <CardBody>
              <h4 className={`${classes.title} ${classes.textCenter}`}>
                {document.node.title}
              </h4>
              <div className={classes.descriptionfont}>{desc}</div>
            </CardBody>
          </Card>
        );
      } else {
        RightCards.push(
          <Card
            blog
            className={classes.customCard}
            style={{ marginTop: `10px` }}
            key={`right-terms-${i}`}
          >
            <CardBody>
              <h4 className={`${classes.title} ${classes.textCenter}`}>
                {document.node.title}
              </h4>
              <div className={classes.descriptionfont}>{desc}</div>
            </CardBody>
          </Card>
        );
      }
    } else {
      Title = (
        <div key={`title-terms-${i}`}>
          <h5 className={classes.descriptionfont}>{desc}</h5>
        </div>
      );
    }
  }
  }
  const timeoutScroll=(scroll)=>{
    const element = browserDocument.getElementById(browserWindow.location.hash);
    setTimeout(() => {
      browserWindow.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? (element.offsetTop+scroll) : 0
      });
    }, 100);
  }
  useEffect(()=>{
    if(browserWindow.location.hash=='#disclaimer'){
      timeoutScroll(80)
    }
    else if(browserWindow.location.hash=='#privacy_policy'){
      timeoutScroll(100)
    }
    else{
      timeoutScroll(100)
    }
  }, []);
  if(browserWindow.location.hash=='#disclaimer'){
    timeoutScroll(80)
  }
  else if(browserWindow.location.hash=='#privacy_policy'){
    timeoutScroll(100)
  }
  else{
    timeoutScroll(100)
  }
  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <div>
      <Helmet key="helmetTags">
        <title>Disclaimer & Privacy Policy | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <meta name="title" content="Disclaimer & Privacy Policy | StarHealth.in" />
          <meta name="twitter:title" content="Disclaimer & Privacy Policy | StarHealth.in" />
          <link rel="canonical" href="https://www.starhealth.in/disclaimer" />
          <meta
            name="description"
            content="Read Terms and Condition of Star Health and Allied Insurance Company Limited, Chennai - 600 034. We have registered with IRDAI as a General Insurance Company with the Registration Number: 129"
          />
          <meta
            name="description"
            content="Star Health Insurance Privacy Policy, we committed to protect our customers privacy and information that was shared while registering with us."
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online, Health Insurance Specialist In India."
          />
          <meta property="og:description" content="Star Health Insurance Privacy Policy, we committed to protect our customers privacy and information that was shared while registering with us." />
          <meta name="twitter:description" content="Star Health Insurance Privacy Policy, we committed to protect our customers privacy and information that was shared while registering with us." />
          
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online, Health Insurance Specialist In India"
          />
          <meta property="og:title" content="Disclaimer & Privacy Policy | StarHealth.in" />
          <meta property="og:description" content="Read Terms and Condition of Star Health and Allied Insurance Company Limited, Chennai - 600 034. We have registered with IRDAI as a General Insurance Company with the Registration Number: 129" />
          <meta property="og:url" content="https://www.starhealth.in/disclaimer"/>
          <meta name="Disclaimer & Privacy Policy | StarHealth.in" />
          <meta name="twitter:description" content="Read Terms and Condition of Star Health and Allied Insurance Company Limited, Chennai - 600 034. We have registered with IRDAI as a General Insurance Company with the Registration Number: 129" />
          <meta property="twitter:url" content="https://www.starhealth.in/disclaimer" />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.section}>
          <div id={`#disclaimer`} ref={disclaimer} className={classes.container}>
          <GridContainer className={classes.container}>
          <GridItem 
                md={10}
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter} ${classes.h2Title}`}
              >
                Disclaimer
              </GridItem>
              <GridItem
                md={10}
                className={`${classes.mrAuto} ${classes.mlAuto}`}
              >
                <div>
                  <h5>
                {/* <Markdown className={classes.markDownFont}>{DisclaimerpageContent}</Markdown> */}
                {sevaApp() ? <Markdown className={classes.markDownFont}>{(DisclaimerpageContent).replace(stringSearchRegExp, APP)}</Markdown> : <Markdown className={classes.markDownFont}>{(DisclaimerpageContent.replace(stringSearchRegExp, WEBSITE))}</Markdown>}
                </h5>
                </div>
              </GridItem>
            </GridContainer>
            
            </div>
            <div id={`#privacy_policy`}>
            <GridContainer className={classes.container}>
            <GridItem 
                md={10}
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter} ${classes.h2Title}`}
              >
                Privacy Policy
              </GridItem>
              <GridItem
                md={10}
                className={`${classes.mrAuto} ${classes.mlAuto}`}
              >
                <div ref={privacy_policy} style={{ marginBottom: 50 }}>
                  <h5 className={classes.customFontSize}>
                    {/* <Markdown style={{fontSize:'17px'}} className={classes.removeMargin}>{Privacydata.description}</Markdown> */}
                    {sevaApp() ? <Markdown style={{fontSize:'17px'}} className={classes.removeMargin}>{(Privacydata.description).replace(stringSearchRegExp, APP)}</Markdown> : <Markdown style={{fontSize:'17px'}} className={classes.removeMargin}>{(Privacydata.description.replace(stringSearchRegExp, WEBSITE))}</Markdown> }
                  </h5>
                </div>
              </GridItem>
              
            </GridContainer>
           
            </div>
            <div>
            <div id={`#terms_of_usage`} ref={terms} className={classes.termscontainer}>
            <GridContainer 
             md={10}
             className={`${classes.mrAuto} ${classes.mlAuto} ${classes.container}`}>
            <GridItem 
                md={10}
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter} ${classes.h2Title}`}
              >
                Terms of Usage
              </GridItem>
              <GridItem
                md={8}
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter} ${classes.markDownFont}`}
                style={{ paddingBottom: 5 }}
              >
                {Title}
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                {LeftCards}
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                {RightCards}
              </GridItem>
            </GridContainer>
            </div>
            </div>
        </div>
      </div>
      </div>
    </Layout>
  );
};
export default withStyles(termsStyle)(Disclaimer);

Disclaimer.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export const disclaimerQuery = graphql`
  query Hell {
    allStrapiEntities(filter: { category: { in: ["disclaimer", "privacy_policy","terms_data", "terms_header"] } },
    sort:{
      fields:sequence,
      order:ASC
    } ) 
    {
      edges {
        node {
          strapiId
          title
          description
          category
          summary
          sequence
        }
      }
    }
    allStrapiImages(filter: { section: { category: { eq: "disclaimer" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
