import {
  container,
  title,
  main,
  mainRaised,
  mrAuto,
  blackColor,
  mlAuto,
  description,
  section
} from "assets/jss/material-kit-pro-react.jsx";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";

const aboutUsStyle = theme => ({
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  description,
  ...customSelectStyle,
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
    "&, & + h4": {
      color: blackColor
    },
    "&, & + h2": {
      color: `inherit`
    }
  },
  textCenter: {
    textAlign: `center`
  },
  h2Title:{
    color: `#3C4858`,
    fontSize:'1.75rem',
    textDecoration: `none`,
    fontWeight: `700`,
    marginTop: `30px`,
    minHeight: `32px`,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    [theme.breakpoints.down('md')]: {
    color: `#3C4858 !important`,
    textDecoration: `none !important`,
    fontWeight: `700 !important`,
    marginTop: `30px !important`,
    minHeight: `32px !important`,
    fontFamily: `"Roboto Slab", "Times New Roman", serif !important`,
    fontSize:'28px !important'
    }
      },
  paddingcontactfix: {
    [theme.breakpoints.down(`md`)]: {
      padding: `0px !important`
    }
  },
  team: {
    padding: `80px 0px`
  },
  markDownFont:{
    fontSize:'17px',
    "& p":{
      fontSize:'17px !important'
    }
  },

  customFontSize:{
    fontSize:'17px',
    "& p":{
      fontSize:'17px !important'
    },
    "& b":{
      fontSize:'17px !important'
    },
    "& h4":{
      fontSize:'17px !important'
    },
    "& h3":{
      color: `#3C4858`,
      fontSize:'23px',
      textDecoration: `none`,
      fontWeight: `700`,
      marginTop: `15px`,
      marginBottom: `16px`,
      minHeight: `32px`,
      fontFamily: `"Roboto Slab", "Times New Roman", serif`,
      [theme.breakpoints.down('md')]: {
      color: `#3C4858 !important`,
      textDecoration: `none !important`,
      fontWeight: `700 !important`,
      marginTop: `30px !important`,
      marginBottom: `25px !important`,
      minHeight: `32px !important`,
      fontFamily: `"Roboto Slab", "Times New Roman", serif !important`,
      fontSize:'28px !important'
    }
    }
  },
  removeMargin:{
    "& ol":{
      "& ul":{
       marginBottom:'0px !important'
      }
    },
  },
  termscontainer:{
  padding:'0px !important',
  paddingLeft:'8% !important',
  paddingRight:'8% !important',
  "@media (max-width: 790px)": {
    paddingLeft:'2% !important',
  paddingRight:'2% !important',
  },
  "@media (max-width: 500px)": {
    paddingLeft:'2% !important',
  paddingRight:'2% !important',
  },
  },
  cardHeader1: {
    textAlign: `center`,
    fontSize: 24,
    borderRadius: `3px`,
    padding: `1.5rem 15px`,
    marginLeft: `50px`,
    marginRight: `50px`,
    marginTop: `-32px`,
    border: `0`,
    marginBottom: `-10px`
  },
  LayoutText1: {
    marginTop: `-180px`,
    paddingLeft: `60px`,
    fontStyle: `bold`,
    fontSize: 58,
    color: `#000066`,
    lineHeight: `100%`,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`
  },
  h1title:{
    ...title,
    fontSize:'38px'
    },
  LayoutText2: {
    marginTop: `-260px`,
    paddingLeft: `60px`,
    fontStyle: `normal`,
    fontSize: 28,
    color: `black`,
    lineHeight: `100%`
  },
  block: {
    color: `inherit`,
    padding: `0.9375rem`,
    fontWeight: `500`,
    fontSize: `12px`,
    textTransform: `uppercase`,
    borderRadius: `3px`,
    textDecoration: `none`,
    position: `relative`,
    display: `block`
  },
  displayFlex: {
    display: `flex`,
    marginTop: `0px`
  },
  inlineBlock: {
    display: `inline-block`,
    padding: `0px`,
    width: `auto`
  },
  list: {
    marginBottom: `0`,
    padding: `0`,
    marginTop: `0`
  },
  left: {
    float: `left!important`,
    display: `block`
  },
  right: {
    padding: `15px 0`,
    margin: `0`,
    float: `right`
  },
  icon: {
    width: `18px`,
    height: `18px`,
    top: `3px`,
    position: `relative`
  },
  noShadow: {
    boxShadow: `none !important`,
    border: `1px solid !important`,
    borderColor: `#eee !important`
  },
  section: {
    ...section,
    padding: `70px 0px`
  },

  textcenter1: {
    fontWeight: `bold`
  },
  coloring: {
    color: `blue`
  },
  customCard: {
    boxShadow: `none`,
    border: `1px solid #eee`,
    "&:hover": {
      boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4)`
    }
  },
  sizefix: {
    [theme.breakpoints.down(`md`)]: {
      paddingLeft: `0px !important`,
      paddingRight: `0px !important`
    }
  },
  buttonfix: {
    [theme.breakpoints.down(`sm`)]: {
      width: `100% !important`
    }
  },
  marginfix: {
    marginRight: 120,
    [theme.breakpoints.down(`md`)]: {
      marginRight: `0px`
    }
  },
  textsizefix: {
    fontSize: `14px !important`,
    [theme.breakpoints.down(`md`)]: {
      fontSize: `12.5px !important`
    }
  },
  overlay: {
    position: `absolute`,
    top: `-370px`,
    right: `80px`
  }

  // .hvr-float {
  //   display: inline-block;
  //   vertical-align: middle;
  //   -webkit-transform: perspective(1px) translateZ(0);
  //   transform: perspective(1px) translateZ(0);
  //   box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  //   -webkit-transition-duration: 0.3s;
  //   transition-duration: 0.3s;
  //   -webkit-transition-property: transform;
  //   transition-property: transform;
  //   -webkit-transition-timing-function: ease-out;
  //   transition-timing-function: ease-out;
  // }
  // .hvr-float:hover, .hvr-float:focus, .hvr-float:active {
  //   -webkit-transform: translateY(-8px);
  //   transform: translateY(-8px);
  // }
});

export default aboutUsStyle;
